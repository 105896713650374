import React from 'react';
import classes from './DecorativeTab.module.css';

const DecorativeTab = ({ width, height, color, bgcolor, border, radius, font, children, position, main}) => {
  const style = {
    width: width || 'auto',
    height: height || 'auto',
    border: main ? '0.8px solid #026336' : `0.833px solid ${border}`,
    background: main ? 'rgba(0, 255, 136, 0.20)' : `rgb(${bgcolor})`,
    color: color || '#000',
    top: position?.top,
    left: position?.left,
    right: position?.right,
    bottom: position?.bottom,
    borderRadius: radius,
    fontSize: font
  };

  return (
    <div className={classes.DecorativeTab} style={style}>
      {children}
    </div>
  );
};

export default DecorativeTab;