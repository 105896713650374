import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  link: '',
};

const linkSlice = createSlice({
  name: 'link',
  initialState,
  reducers: {
    setLink(state, action) {
      state.link = action.payload;
    },
  },
});

export const { setLink } = linkSlice.actions;

export default linkSlice.reducer;