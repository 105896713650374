import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { auth, authorizeUser, getUser } from './api/userApi';
import { Provider } from 'react-redux';
import { store } from './store/store'


const tg = window.Telegram ? window.Telegram.WebApp : null;

/*const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  //True, если мобильное
};

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  //True, если поддерживает тапы
};*/

if (tg) {
  tg.expand();
  tg.ready();
  tg.isClosingConfirmationEnabled = true; // Включаем подтверждение перед закрытием
}

const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <WebAppProvider>
        <Provider store={store}>
          <App/>
        </Provider>
      </WebAppProvider>
    </React.StrictMode>
);