export const secondsToHMS = (seconds) => {
  const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
  const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
  const s = (seconds % 60).toString().padStart(2, '0');
  
  return `${h}:${m}:${s}`;
}

export const formatNumberToM = (num) => {
  if (num < 1000000) return num;
  return (num / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}