import { useState } from 'react';
import Button from '../../ui/Button/Button';
import classes from './Onboarding.module.css'
import Earn from '../../pages/Earn/Earn';
import Home from '../../pages/Home/Home';
import Boost from '../../pages/Boost/Boost';

const text = [
  <p>Welcome to GEKKO!<br/>A world where<br/>every tap brings coins!</p>,
  <p>Hi! I'm GEKKO.<br/> Let me show you<br/> how it all works!</p>,
  <p>Here you will tap<br/> and earn GEKKO.<br/> More taps - more coins</p>,
  <p>Here you can improve <br/>your skills and get more <br/>coins for each tap. <br/>Click on BOOST <br/>to see available upgrades</p>,
  <p>These boosts will help <br/> you become a tap master</p>,
  <p>Complete tasks and get <br/>extra GEKKOs! <br/>The more tasks you <br/>complete, the more <br/>GEKKOs you get</p>,
  <p>You're ready to start <br/>your adventure in the <br/>world of GEKKO!</p>
]

const positions = [
  {bottom: '136px'},
  {bottom: '300px', right: '30px'},
  {bottom: '235px', left: '30px'},
  {bottom: '235px', right: '30px'},
  {bottom: '130px', left: '30px'},
  {bottom: '235px'},
  {bottom: '235px', left: '30px'},
]

const tabs = [
  <></>,
  <Home/>,
  <Home/>,
  <Boost/>,
  <Earn/>,
  <Home/>
]



const Onboarding = ({ content, setContent, setActiveTab, onEnd }) => {
  
  const clickHandler = () => {
    if (content > 0) {
      setContent((prev) => prev + 1)
      setActiveTab(tabs[content])
    }
    if (content >= 6) {
      onEnd()
      console.log('end')
    }
  }

  return (
    <div className={classes.Onboarding} onClick={clickHandler}>
      <div className={classes.modalContent} style={positions[content]}>
        {text[content]}
      </div>
      {content === 1 &&
          <div className={classes.button}>Go</div>
        }
    </div>
  )
}

export default Onboarding;