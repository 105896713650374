import classes from './DailyTab.module.css'

const DailyTab = ({ day, reward, unactive }) => {
  return (
    <div className={classes.DailyTab} style={{opacity: `${unactive ? '0.5' : '1'}`}}>
      <div className={classes.shadow}></div>

      <div className={classes.left}>
        {`Day ${day}`}
      </div>

      <div className={classes.right}>
        {reward}
      </div>

    </div>
  )
}

export default DailyTab;