import React, { useState, useRef, useEffect } from 'react';
import classes from './Gekko.module.css';
import { useSelector } from 'react-redux';

const Gekko = () => {
  const videoRef = useRef(null);
  const intervalRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;

    return () => clearInterval(intervalRef.current)
  }, []);

  const handleVideoPlayPause = () => {
    if (user.energy > user.tap_boost_level) {
      if (!isPlaying) {
        videoRef.current.play();
        setIsPlaying(true);
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        if (videoRef.current) {
          intervalRef.current = setInterval(() => {
            if (videoRef?.current?.currentTime === 0) {
              clearInterval(intervalRef.current);
              videoRef.current.pause();
              videoRef.current.currentTime = 0;
              setIsPlaying(false);
            }
          }, videoRef.current.duration);
        }
      }, 1000);

      setTimeoutId(newTimeoutId);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div className={classes.Gekko} onTouchStart={handleVideoPlayPause}>
      <video
        ref={videoRef}
        className={classes.gekkoVid}
        controls={false}
        loop
        preload="auto"
        muted
        width="100%"
        height="auto"
        playsInline
        controlsList="nodownload nofullscreen noremoteplayback"
        style={{ pointerEvents: 'none' }}
        poster="/assets/poster.jpg"
      >
        <source src={'/assets/Gekko.webm'} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Gekko;
