import Button from '../Button/Button';
import classes from './LeagueTab.module.css'

const LeagueTab = ({ title, border, bgcolor, opacity, onClick }) => {
  return (
    <div onClick={onClick} className={classes.LeagueTab} style={{border: `1px solid ${border}`, backgroundColor: `rgb(${bgcolor})`, opacity: opacity}}>
      <p className={classes.title}>{title}</p>
    </div>
  )
}

export default LeagueTab;