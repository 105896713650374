import axios from "axios";
import { decode } from "./userApi";

export const checkTask = async (task, token) => {
  try {
    const response = await axios.post(
      `https://api.gekkoapp.online/tasks/check/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    return null;
  }
};

export const checkLinkTask = async (task, token) => {
  try {
    const response = await axios.post(
      `https://api.gekkoapp.online/tasks/complete_link/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    return null;
  }
};

export const getTasks = async (token) => {
  try {
    const response = await axios.get(
      `https://api.gekkoapp.online/tasks/active/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка получения заданий', err);
    return null;
  }
};

export const dailyReward = async (token) => {
  try {
    const response = await axios.post(
      `https://api.gekkoapp.online/users/daily_reward/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    return null;
  }
};