import classes from './UserTab.module.css'

const UserTab = ({ img, name, income, rightTitle, rightContent, color, border}) => {

  const style = {
    background: `linear-gradient(180deg, #FFF 26.47%, rgb(${color}) 152.94%)`,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  }

  return (
    <div className={classes.UserTab} style={{backgroundColor: `rgb(${color})`, border: `1px solid ${border}`}}>
      
      <div className={classes.shadow} style={{
        background: `linear-gradient(rgba(${color}, 0.1), rgba(${color}, 0.9), rgba(${color}, 1))`
        }}></div>

      <div className={classes.userData}>
        <div className={classes.border} style={{border: `1px solid ${border}`}}>
          <div className={classes.blur} style={{border: `3px solid ${border}`}}></div>
          <img src={img} alt={name} className={classes.img}/>
        </div>
        <div className={classes.info}>
          <p className={classes.name}>{name}</p>
          <p className={classes.income}>{income} GEK</p>
        </div>
      </div>
      <div className={classes.right}>
        <p className={classes.rightTitle}>{rightTitle}</p>
        <p className={classes.rightContent}>{rightContent}</p>
      </div>
    </div>
  )
}

export default UserTab;