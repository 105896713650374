import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadedTasks: [],
  dailyTasks: [],
  specialTasks: [],
  buttonsState: [],
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasks(state, action) {
      const tasks = action.payload
      state.loadedTasks = tasks
      state.dailyTasks = tasks.filter(task => task.task_category === 'daily');
      state.specialTasks = tasks.filter(task => task.task_category === 'special');
      state.buttonsState = tasks.map(task => ({
        id: task.id,
        status: task.status,
      }));
    },
    updateButtonState(state, action) {
      const { id, status } = action.payload;

      const existingButton = state.buttonsState.find(button => button.id === id);

      if (existingButton) {
        existingButton.status = status;
      } else {
        state.buttonsState.push({ id, status });
      }
    },
  },
});

export const { setTasks, updateButtonState } = tasksSlice.actions;
export default tasksSlice.reducer;