import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice'
import leagueReducer from './leagueSlice'
import friendsReducer from './friendsSlice'
import linkReducer from './linkSlice';
import tasksReducer from './tasksSlice';


export const store = configureStore({
  reducer: {
    user: userReducer,
    league: leagueReducer,
    friends: friendsReducer,
    link: linkReducer,
    tasks: tasksReducer,
  },
});
