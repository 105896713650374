import MenuItem from '../MenuItem/MenuItem';
import classes from './Menu.module.css'
import home from '../../../assets/svgs/menu-home.svg'
import boost from '../../../assets/svgs/menu-boost.svg'
import mine from '../../../assets/svgs/menu-mine.svg'
import earn from '../../../assets/svgs/menu-earn.svg'
import friends from '../../../assets/svgs/menu-friends.svg'
import homeActive from '../../../assets/svgs/menu-home-active.svg'
import boostActive from '../../../assets/svgs/menu-boost-active.svg'
import mineActive from '../../../assets/svgs/menu-mine-active.svg'
import earnActive from '../../../assets/svgs/menu-earn-active.svg'
import friendsActive from '../../../assets/svgs/menu-friends-active.svg'
import { useState } from 'react';
import Home from '../../pages/Home/Home';
import Boost from '../../pages/Boost/Boost';
import Mine from '../../pages/Mine/Mine';
import Earn from '../../pages/Earn/Earn';
import Friends from '../../pages/Friends/Friends';
import League from '../../pages/League/League';
import Social from '../../pages/Social/Social';

const Menu = ({sendTap, token, setActiveTab }) => {

  const [activeIndex, setActiveIndex] = useState(0);

  const pages = [
    <Home sendTap={sendTap} token={token}/>,
    <Boost token={token}/>,
    <League token={token}/>,
    <Earn token={token} showSocial={() => setActiveTab(<Social token={token}/>)}/>,
    <Friends token={token}/>,
  ]

  const handleMenuItemClick = (index) => {
    setActiveIndex(index);
    setActiveTab(pages[index])
  };

  return (
    <div className={classes.Menu} onTouchEnd={(e) => e.stopPropagation()}>
        <MenuItem
          title={'Home'}
          img={home}
          imgActive={homeActive}
          clickHandler={() => handleMenuItemClick(0)}
          isActive={activeIndex === 0}
        />
        <MenuItem
          title={'Boost'}
          img={boost}
          imgActive={boostActive}
          clickHandler={() => handleMenuItemClick(1)}
          isActive={activeIndex === 1}
        />
        <MenuItem
          title={'League'}
          img={mine}
          imgActive={mineActive}
          clickHandler={() => handleMenuItemClick(2)}
          isActive={activeIndex === 2}
        />
        <MenuItem
          title={'Earn'}
          img={earn}
          imgActive={earnActive}
          clickHandler={() => handleMenuItemClick(3)}
          isActive={activeIndex === 3}
        />
        <MenuItem
          title={'Friends'}
          img={friends}
          imgActive={friendsActive}
          clickHandler={() => handleMenuItemClick(4)}
          isActive={activeIndex === 4}
        />
    </div>
  )
}

export default Menu;