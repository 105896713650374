import classes from './MenuItem.module.css'

const MenuItem = ({title, img, imgActive, clickHandler, isActive}) => {
  return (
    <>
    {isActive ?
      <div onClick={clickHandler} className={classes.MenuItemActive}>
          <div className={classes.line}></div>
          <div className={classes.hider}></div>
          <div className={classes.circle}>
            <img src={imgActive} alt={title} className={classes.image}/>
          </div>
          <p className={classes.titleActive}>{title}</p>
      </div>
    :
      <div onClick={clickHandler} className={classes.MenuItem}>
        <img src={img} alt={title} className={classes.image}/>
        <p className={classes.title}>{title}</p>
      </div>
    }
    </>
  )
}

export default MenuItem;