import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTapSocket } from '../../../api/tapApi'
import classes from './Home.module.css';
import energyIcon from '../../../assets/svgs/energy-icon.svg';
import ClickBubble from '../../ui/ClickBubble/ClickBubble';
import Gekko from '../../ui/Gekko/Gekko';
import uuid4 from "uuid4";
import { balancePlusOne, updateBalance, updateEnergy, updateMiningSecodns } from '../../../store/userSlice';
import { claim } from '../../../api/boosterApi';
import { formatNumberToM } from '../../../utils';

//Плавные тапы
//Проверить онбординг

const leagues = [
  'Bronze', 'Silver', 'Gold',
  'Platinum', 'Diamond', 'Saphyre',
  'Emerald', 'Ruby', 'Obsidian',
  'White gold',
];

const Home = ({ sendTap, token }) => {
  const [texts, setTexts] = useState([]);
  const [touchCount, setTouchCount] = useState(0);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const touchRef = useRef();
  const timeoutRef = useRef(null); // Для хранения таймера
  const flagRef = useRef(true);

  useEffect(() => {
    touchRef.current = touchCount;
    localStorage.setItem('taps', JSON.stringify(touchRef.current));
  }, [touchCount]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
      if (touchRef.current > 0) {
        if (sendTap) {
          sendTap(touchRef.current);
        }
        setTouchCount(0);
      }
    };
  }, []);

  const sendTapsAfterTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Очищаем предыдущий таймер
    }
    
    timeoutRef.current = setTimeout(() => {
      if (touchRef.current > 0 && sendTap) {
        sendTap(touchRef.current);
        setTouchCount(0); // Сбрасываем счетчик тапов после отправки
      }
    }, 1000); // 1 секунда тайм-аута
  };

  const handleTouchEnd = (e) => {
    if (user.energy > user.tap_boost_level) {
      const endTouches = Array.from(e.changedTouches).map((touch) => ({
        id: uuid4(),
        text: `+${user.tap_boost_level}`,
        x: touch.clientX,
        y: touch.clientY,
      }));

      setTexts((prevTexts) => [...prevTexts, ...endTouches]);

      const touchCounter = Array.from(e.changedTouches).length;
      const totalBoost = user.tap_boost_level * touchCounter;
      let currentBoost = 0;

      const interval = setInterval(() => {
        if (currentBoost < totalBoost) {
          dispatch(balancePlusOne());
          currentBoost++;
      
          if (currentBoost >= totalBoost) {
            clearInterval(interval);
          }
        } else {
          clearInterval(interval);
        }
      }, 100);
  
      Array.from(e.changedTouches).forEach(() => {
        setTouchCount((prev) => prev + 1);
        sendTapsAfterTimeout(); // Запускаем или перезапускаем таймер при каждом тапе
        dispatch(updateEnergy({ energy: user.energy - user.tap_boost_level }));
      });
    }
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
  };

  const handleAnimationEnd = (id) => {
    setTexts((prevTexts) => prevTexts.filter((text) => text.id !== id));
  };

  const claimHandler = async (e) => {
    e.stopPropagation();
    const response = await claim(token);
    if (response.status === 'success') {
      dispatch(updateBalance({ balance: response.new_balance }));
      dispatch(updateMiningSecodns({ mining_claim_seconds: response.mining_claim_seconds }));
    }
  };

  return (
    <div
      className={classes.Home}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      {texts.map((textObj) => (
        <ClickBubble
          key={textObj.id}
          text={textObj.text}
          x={textObj.x}
          y={textObj.y}
          onAnimationEnd={() => handleAnimationEnd(textObj.id)}
        />
      ))}
      <div className={classes.shadow}></div>
      <img src={'/assets/back.svg'} alt='background' className={classes.background}/>
      <Gekko />
      <div className={classes.container}>
        <div className={classes.sign_container}>
          <div onTouchEnd={(e) => e.stopPropagation()} className={classes.sign}>
            <p className={classes.title}>League</p>
            <p className={classes.value}>{leagues[user.current_league - 1]}</p>
          </div>
          <div onTouchEnd={(e) => e.stopPropagation()} className={classes.sign}>
            <p className={classes.title}>Profit per hour</p>
            <p className={classes.value}>{user.mining_level ? formatNumberToM((100 * 2**(user.mining_level - 1))) : '0'}</p>
          </div>
        </div>
        <div className={classes.main}>
          <h1 className={classes.score}>
            <span className={classes.gradient}>{user.balance}</span>
            <span className={classes.score_shadow}>{user.balance}</span>
            {user.balance}
          </h1>
          <div className={classes.claim}>
            <div onTouchEnd={(e) => e.stopPropagation()} onClick={claimHandler} className={classes.button} style={{opacity: user.mining_claim_seconds < 3600 ? '50%' : '100%'}}>
              <p className={classes.claim_text}>Claim</p>
              <p className={classes.claim_text}>{user.mining_level ? formatNumberToM((user.mining_claim_seconds * (100 * 2**(user.mining_level - 1)) / 3600).toFixed(2)) : '0'}</p>
            </div>
            <div className={classes.energy}>
              <img src={energyIcon} alt='energy' />
              <p className={classes.energy_text}>{Math.floor(user.energy)}/{user.energy_boost_level}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
